import { useRef } from "react";

/**
 * - focus 설정을 하기위한 custom hook
 * - 1. 예제의 htmlElRef 를 기존의 ref 처럼 focus 원하는 input 태그에 ref={htmlElRef} 로 선언합니다.
 * - 2. setFocus 함수 실행시 해당 htmlElRef 에 focus 가 가게됩니다.
 * @example const [htmlElRef, setFocus] = useFocus();
 */

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    // eslint-disable-next-line @babel/no-unused-expressions
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

export default useFocus;
