import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const BasicField = ({
  placeholder,
  value,
  onChange,
  type = "text",
  width = "320px",
  height = "48px",
  disabled = false,
  styles = {},
  errorText = "",
  props = {},
  error = false,
  required = false,
  onKeyPress = () => {},
}) => {
  const classes = customTextField({ width, height });
  return (
    <TextField
      required={required}
      className={classes.root}
      variant={"outlined"}
      type={type}
      onKeyPress={onKeyPress}
      InputProps={{
        style: { backgroundColor: disabled ? "#f5f5f5" : "#ffffff" },
        endAdornment: errorText && (
          <InputAdornment
            className={"errorText"}
            position="end">
            {errorText}
          </InputAdornment>
        ),
        ...props,
      }}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      error={
        error !== false ? (value === "" || value === undefined ? false : !error.test(value)) : error
      }
      style={{ ...styles }}
    />
  );
};

export const IconField = ({
  placeholder,
  value,
  width = "320px",
  height = "48px",
  startAdornment,
  endAdornment,
  onChange,
  styles = {},
  required = false,
  error = false,
  helpText = "",
  disabled = false,
  type = "text",
}) => {
  const classes = customTextField({ width, height });
  return (
    <TextField
      type={type}
      required={required}
      className={classes.root}
      variant={"outlined"}
      InputProps={{
        style: { backgroundColor: disabled ? "#f5f5f5" : "#ffffff" },
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
      }}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      style={{ ...styles }}
      error={error}
      helperText={helpText}
    />
  );
};

const customTextField = makeStyles((theme) => ({
  root: {
    width: (props) => (props.width ? props.width : "320px"),
    height: (props) => (props.height ? props.height : "48px"),
    "& .MuiInputBase-root": {
      width: (props) => (props.width ? props.width : "320px"),
      height: (props) => (props.height ? props.height : "48px"),
      borderRadius: "8px",
      backgroundColor: theme.palette.common.white,
    },
    "& input": {
      color: theme.palette.text.primary,
      fontSize: "14px",
      "&::placeholder": {
        color: theme.palette.text.tertiary,
        opacity: 1,
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#dddddd",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#dddddd",
    },
    "@media (hover: none) ": {
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#dddddd",
      },
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#dddddd",
    },

    "& .errorText": {
      "& p": {
        fontSize: "14px",
        color: theme.palette.error.main,
      },
    },
    "& .Mui-focused .errorText": {
      display: "none",
    },
  },
}));
