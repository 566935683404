import { TextField, useTheme } from "@material-ui/core";
import { forwardRef } from "react";
import { useMedia } from "react-use";

import { useDefaultStyles } from "/common/MakeStyle";

/**
 * - common text field input width custom style
 * - path: /components/common/Mui/Styled/StyledTextField.js
 * @param props - text field input
 * @returns {JSX.Element}
 * @example
 * <StyledTextField
 *     placeholder={"placeholder"}
 *     onChange={(e) => setState(e.target.value)}
 * />
 */
const StyledTextField = (props) => {
  const isFold = useMedia("(max-width: 320px)");

  const theme = useTheme();
  const defaultClasses = useDefaultStyles({ isFold, width: "100%" });

  return (
    <TextField
      className={`${defaultClasses.textField}`}
      {...props}
      variant={props?.variant || "outlined"}
      disabled={props?.disabled || false}
      InputProps={{
        ...props?.InputProps,
        style: {
          backgroundColor: props?.disabled
            ? theme.palette.text.disabled
            : theme.palette.background.paper,
          color: props?.disabled
            ? theme.palette.mode === "light"
              ? theme.palette.grey[600]
              : theme.palette.grey[100]
            : theme.palette.text.primary,
          ...props?.InputProps?.style,
        },
      }}
    />
  );
};

// eslint-disable-next-line react/display-name
export const ForwardRefStyledTextField = forwardRef((props, ref) => {
  return (
    <StyledTextField
      inputRef={ref}
      {...props}
    />
  );
});

export default StyledTextField;
