import { Box, InputAdornment, useTheme } from "@material-ui/core";
import { useEffect } from "react";
import { useController } from "react-hook-form";

import { pxToRemConvertFn } from "/common/Fn";
import StyledTextField from "/components/common/Mui/Styled/StyledTextField";
import useFocus from "/hooks/useFocus";

/**
 * - common text field using react hook form controller
 * - path: /components/common/Mui/Control/ControlTextField.js
 * @param props - text field additional props
 * @returns {JSX.Element}
 * @example
 * <ControlTextField
 *     control={control}
 *     name={"name"}
 *     placeholder={"placeholder"}
 * />
 */
const ControlTextField = (props) => {
  const {
    control,
    name,
    rules = {},
    defaultValue = "",
    errors = {},
    focus = false,
    onCustomChange = (value) => value,
    ...restProps
  } = props;

  const theme = useTheme();
  const [ref, setRef] = useFocus();

  const {
    field: { ref: _ref, ...res },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  useEffect(() => (focus ? setRef() : null), [focus]);

  return (
    <StyledTextField
      data-cy={restProps?.dataCy || name}
      inputRef={ref}
      {...res}
      {...restProps}
      InputProps={{
        ...restProps?.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            {!(name in errors) && !res?.value && rules?.required && (
              <Box
                px={1}
                fontSize={pxToRemConvertFn(14)}
                color={theme.palette.error.main}>
                {rules?.required?.message}
              </Box>
            )}
            {name in errors && (
              <Box
                px={1}
                fontSize={pxToRemConvertFn(14)}
                color={theme.palette.error.main}>
                {errors[name].message}
              </Box>
            )}
            {restProps?.InputProps?.endAdornment}
          </InputAdornment>
        ),
      }}
      onChange={(e) => res.onChange(onCustomChange(e.target.value))}
      onFocus={(e) =>
        e.currentTarget.setSelectionRange(
          e.currentTarget.value.length,
          e.currentTarget.value.length,
        )
      }
    />
  );
};

export default ControlTextField;
