import { Box, useTheme } from "@material-ui/core";
import { useMemo } from "react";

import { pxToRemConvertFn } from "/common/Fn";
import { useDefaultStyles } from "/common/MakeStyle";

/**
 * - common wrapper component
 * @param style - custom style
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @example
 * <Wrapper>
 *   { children contents }
 * </Wrapper>
 */
export const Wrapper = ({ style = {}, children, ...rest }) => {
  return (
    <Box
      sx={{ ...style }}
      {...rest}>
      {children}
    </Box>
  );
};

/**
 * - common span wrapper component
 * @param style - custom style
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @example
 * <Span_Wrapper>
 *   { children contents }
 * </Span_Wrapper>
 */
export const Span_Wrapper = ({ style = {}, children, ...rest }) => {
  return (
    <Box
      component={"span"}
      sx={{ ...style }}
      {...rest}>
      {children}
    </Box>
  );
};

/**
 * - common display flex wrapper component
 * - path: /components/common/Wrapper
 * @param style - custom style
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @example
 * <F_Wrapper>
 *   { children contents }
 * </F_Wrapper>
 */
export const F_Wrapper = ({ style = {}, children, ...rest }) => {
  const defaultClasses = useDefaultStyles();

  return (
    <Box
      className={defaultClasses.f}
      sx={style}
      {...rest}>
      {children}
    </Box>
  );
};

/**
 * - common display flex, align items center wrapper component
 * - path: /components/common/Wrapper
 * @param style - custom style
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @example
 * <F_AIC_Wrapper>
 *   { children contents }
 * </F_AIC_Wrapper>
 */
export const F_AIC_Wrapper = ({ style = {}, children, ...rest }) => {
  const defaultClasses = useDefaultStyles();

  return (
    <Box
      className={defaultClasses.fAIc}
      sx={style}
      {...rest}>
      {children}
    </Box>
  );
};

/**
 * - common display flex, justify content center, align items center wrapper component
 * - path: /components/common/Wrapper
 * @param style - custom style
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @example
 * <F_JCC_AIC_Wrapper>
 *   { children contents }
 * </F_JCC_AIC_Wrapper>
 */
export const F_JCC_AIC_Wrapper = ({ style = {}, children, ...rest }) => {
  const defaultClasses = useDefaultStyles();

  return (
    <Box
      className={defaultClasses.fJCcAIc}
      sx={style}
      {...rest}>
      {children}
    </Box>
  );
};

/**
 * - common display flex, justify content space-between, align items center wrapper component
 * - path: /components/common/Wrapper
 * @param style - custom style
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @example
 * <F_JCSB_AIC_Wrapper>
 *   { children contents }
 * </F_JCSB_AIC_Wrapper>
 */
export const F_JCSB_AIC_Wrapper = ({ style = {}, children, ...rest }) => {
  const defaultClasses = useDefaultStyles();

  return (
    <Box
      className={defaultClasses.fJCsbAIc}
      sx={style}
      {...rest}>
      {children}
    </Box>
  );
};

/**
 * - common display flex, justify content flex-end, align items center wrapper component
 * - path: /components/common/Wrapper
 * @param style - custom style
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @example
 * <F_JCSB_AIC_Wrapper>
 *   { children contents }
 * </F_JCSB_AIC_Wrapper>
 */
export const F_JCFE_AIC_Wrapper = ({ style = {}, children, ...rest }) => {
  const defaultClasses = useDefaultStyles();

  return (
    <Box
      className={defaultClasses.fJCfeAIc}
      sx={style}
      {...rest}>
      {children}
    </Box>
  );
};

/**
 * - common data wrapper component
 * - path: /components/common/Wrapper
 * @param style - custom style
 * @param fontSize
 * @param fontWeight
 * @param children
 * @param rest
 * @returns {JSX.Element}
 *
 */
export const Data_Wrapper = ({
  style = {},
  fontSize = 14,
  fontWeight = 300,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const convertFontWeight = useMemo(() => {
    switch (fontWeight) {
      case 400:
        return theme.typography.fontWeightRegular;
      case 500:
        return theme.typography.fontWeightMedium;
      case 600:
        return theme.typography.fontWeightSemiBold;
      case 700:
        return theme.typography.fontWeightBold;
      case 800:
        return theme.typography.fontWeightExtraBold;
      default:
        return theme.typography.fontWeightLight;
    }
  }, [fontWeight]);

  return (
    <Box
      sx={{
        fontSize: pxToRemConvertFn(fontSize),
        fontWeight: convertFontWeight,
        color: theme.palette.text.primary,
        ...style,
      }}
      {...rest}>
      {children}
    </Box>
  );
};
