/**
 * Adornment 컴포넌트 모아놓는 곳 입니다.
 */

import { Box, IconButton, InputAdornment, useTheme } from "@material-ui/core";
import Image from "next/image";
import { useMemo } from "react";
import { MdOutlineSearch, MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useMedia } from "react-use";

import { pxFn } from "/common/Fn";
import { commonImagePropsFn } from "/common/Image";
import { useDefaultStyles } from "/common/MakeStyle";
import { PrimarySubBtn } from "/components/common/Button/SubButton";
import { F_JCC_AIC_Wrapper } from "/components/common/Wrapper";

export const SearchStartAdornment = ({ value, onClick = null }) => {
  const theme = useTheme();

  return (
    <InputAdornment position="start">
      {
        <F_JCC_AIC_Wrapper
          onClick={onClick}
          style={{ cursor: "pointer" }}>
          <MdOutlineSearch
            data-cy={"closeSearchBtn"}
            color={value ? theme.palette.text.primary : theme.palette.text.tertiary}
          />
        </F_JCC_AIC_Wrapper>
      }
    </InputAdornment>
  );
};

export const SearchToggleAdornment = ({ onClick }) => (
  <InputAdornment position="start">
    <F_JCC_AIC_Wrapper
      onClick={onClick}
      style={{ cursor: "pointer" }}>
      <MdOutlineSearch size={pxFn(22)} />
    </F_JCC_AIC_Wrapper>
  </InputAdornment>
);

export const SearchEndAdornment = ({ value, onClickSearchOff, height = 36 }) => {
  const isFold = useMedia("(max-width: 320px)");
  const multiple = useMemo(() => (isFold ? 0.6 : 1), [isFold]);

  const SEARCH_END_ADORNMENT_PRIMARY_SUB_BTN_PROPS = useMemo(
    () => ({
      width: isFold ? 42 : 56,
      height: isFold ? 34 : height,
      fontSize: pxFn(isFold ? 12 : 14),
      text: "검색",
      type: "submit",
      dataCy: "searchEndAdornment",
    }),
    [isFold],
  );

  const defaultClass = useDefaultStyles();

  return (
    <InputAdornment position="end">
      {value && (
        <Box
          pr={1.5}
          className={defaultClass.fAIc}
          onClick={onClickSearchOff}
          data-cy={"searchClearBtn"}>
          <Image
            alt={""}
            {...commonImagePropsFn.searchClear(20 * multiple, 20 * multiple)}
          />
        </Box>
      )}
      <PrimarySubBtn {...SEARCH_END_ADORNMENT_PRIMARY_SUB_BTN_PROPS} />
    </InputAdornment>
  );
};

export const SearchFilterEndAdornment = ({ value, handleClick, onClickSearchOff, height = 36 }) => {
  const isFold = useMedia("(max-width: 320px)");
  const multiple = useMemo(() => (isFold ? 0.6 : 1), [isFold]);

  const SEARCH_END_ADORNMENT_PRIMARY_SUB_BTN_PROPS = useMemo(
    () => ({
      width: isFold ? 42 : 56,
      height: isFold ? 34 : height,
      fontSize: pxFn(isFold ? 12 : 14),
      text: "검색",
      type: "submit",
    }),
    [isFold],
  );

  const defaultClass = useDefaultStyles();

  return (
    <InputAdornment position="end">
      {value && (
        <Box
          pr={1.5}
          className={defaultClass.fAIc}
          onClick={onClickSearchOff}
          data-cy={"searchClearBtn"}>
          <Image
            alt={""}
            {...commonImagePropsFn.searchClear(20 * multiple, 20 * multiple)}
          />
        </Box>
      )}
      <PrimarySubBtn
        {...SEARCH_END_ADORNMENT_PRIMARY_SUB_BTN_PROPS}
        handleClick={handleClick}
      />
    </InputAdornment>
  );
};

export const InputEndAdornment = ({ btnProps } = {}) => (
  <InputAdornment position="end">
    <PrimarySubBtn {...btnProps} />
  </InputAdornment>
);

export const IdStartAdornment = () => (
  <InputAdornment position={"start"}>
    <Image
      alt={"icon"}
      src={"/images/ic-id.png"}
      width={"16"}
      height={"16"}
    />
  </InputAdornment>
);

export const PassWordStartAdornment = () => (
  <InputAdornment position={"start"}>
    <Image
      alt={"icon"}
      src={"/images/ic-password_off.png"}
      width={"16"}
      height={"16"}
    />
  </InputAdornment>
);

export const PassWordEndAdornment = ({ showPassword, showPasswordToggle, children }) => (
  <InputAdornment position="end">
    {children}
    <IconButton
      onClick={showPasswordToggle}
      onMouseDown={(event) => event.preventDefault()}
      edge="end">
      {showPassword ? <MdOutlineVisibilityOff size={16} /> : <MdOutlineVisibility size={16} />}
    </IconButton>
  </InputAdornment>
);

export const QrCodeStartAdornment = () => (
  <InputAdornment position="start">
    <Image
      alt={""}
      src={"/images/ic-qrCode.png"}
      width={"16px"}
      height={"16px"}
      layout={"fixed"}
    />
  </InputAdornment>
);

export const SearchHistoryEndAdornment = ({ height = 36 }) => {
  const isFold = useMedia("(max-width: 320px)");

  const SEARCH_END_ADORNMENT_PRIMARY_SUB_BTN_PROPS = useMemo(
    () => ({
      width: isFold ? 42 : 56,
      height: isFold ? 34 : height,
      fontSize: pxFn(isFold ? 12 : 14),
      text: "검색",
      type: "submit",
      dataCy: "searchHistoryAdornment",
    }),
    [isFold],
  );

  return (
    <InputAdornment position="end">
      <PrimarySubBtn {...SEARCH_END_ADORNMENT_PRIMARY_SUB_BTN_PROPS} />
    </InputAdornment>
  );
};
