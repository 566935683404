import { makeStyles } from "@material-ui/core/styles";
import Image from "next/image";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { MainColorButton, MainLineButton } from "/components/common/Button/MainButton";
import { BasicField } from "/components/common/CustomTextField";

/**
 * - find p.w component
 * - path: /feature/mobile/login/mobileContents/findPwd
 * @param toggle
 * @returns {JSX.Element}
 * @deprecated 초기 기획에 있었지만 현재는 활용되자 않음
 */
const FindPwd = ({ toggle }) => {
  const classes = useStyles();
  const methods = useForm();
  const { handleSubmit, control, watch } = methods;

  const [step, setStep] = useState(1);

  const onSubmit = async (_data) => {
    setStep(2);
  };

  return (
    <div className={classes.Modal}>
      <div className={classes.ModalTop}>
        <div className={"MenuNm"}>비밀번호 찾기</div>
        <Image
          alt={"close button"}
          src={"/images/ic-close.png"}
          height={20}
          width={20}
          onClick={toggle}
        />
      </div>
      <div className={classes.ModalBody}>
        {step === 1 && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.infoBox}>
                <Controller
                  name={"id"}
                  control={control}
                  render={({ field }) => (
                    <BasicField
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={"아이디"}
                      width={"calc(100vw - 48px)"}
                    />
                  )}
                />
                <Controller
                  name={"newPwd"}
                  control={control}
                  render={({ field }) => (
                    <BasicField
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={"사업자 등록번호"}
                      width={"calc(100vw - 48px)"}
                    />
                  )}
                />
                <Controller
                  name={"telNum"}
                  control={control}
                  render={({ field }) => (
                    <BasicField
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={"전화번호"}
                      width={"calc(100vw - 48px)"}
                    />
                  )}
                />
                <div className={classes.saveBtn}>
                  <MainColorButton
                    width={"calc(100vw - 48px)"}
                    text={"완료"}
                    type={"submit"}
                    disabled={
                      !(
                        watch("id") !== "" &&
                        watch("id") !== undefined &&
                        watch("newPwd") !== "" &&
                        watch("newPwd") !== undefined &&
                        watch("telNum") !== "" &&
                        watch("telNum") !== undefined
                      )
                    }
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        )}
        {step === 2 && (
          <div className={classes.sendEmail}>
            <Image
              alt={""}
              src={"/images/img-sendEmail.png"}
              height={"119px"}
              width={"135px"}
            />
            <div className={classes.email}>colocasdfasdf@gmail.com</div>
            <div>임시 비빌번호를 발송할까요?</div>
            <div className={"buttons"}>
              <MainLineButton
                text={"취소"}
                width={"calc((100vw - 24px) / 3)"}
                styles={{ marginRight: "10px" }}
                handleClick={() => setStep(1)}
              />
              <MainColorButton
                text={"발송"}
                width={"calc((100vw - 24px) / 3 * 2)"}
                handleClick={() => setStep(3)}
              />
            </div>
          </div>
        )}
        {step === 3 && (
          <div className={classes.sendEmailDone}>
            <Image
              alt={""}
              src={"/images/img-sendEmail2.png"}
              height={"119px"}
              width={"135px"}
            />
            <div className={classes.email}>colocasdfasdf@gmail.com</div>
            <div>임시 비빌번호 발송이 완료되었어요.</div>
            <div className={"button"}>
              <MainColorButton
                text={"발송"}
                width={"100%"}
                handleClick={() => location.reload()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FindPwd;

const useStyles = makeStyles((theme) => ({
  Modal: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    padding: "0 24px",
    backgroundColor: theme.palette.common.white,
    overflowY: "auto",
    height: "100%",
  },
  ModalTop: {
    height: "56px",
    marginBottom: "16px",
    position: "relative",
    "& .MenuNm": {
      fontSize: "16px",
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      textAlign: "center",
      marginTop: "18px",
    },
    "& > span": {
      position: "absolute !important",
      right: 0,
      top: 0,
    },
  },

  ModalBody: {},

  infoBox: {
    display: "flex",
    flexDirection: "column",
    height: "170px",
    justifyContent: "space-between",
  },
  saveBtn: {
    position: "fixed",
    bottom: 0,
    padding: "24px 0",
  },
  sendEmail: {
    textAlign: "center",
    marginTop: "50%",
    "& div:nth-child(3n)": {
      fontSize: "24px",
      color: theme.palette.text.primary,
      lineHeight: 1.33,
    },
    "& .buttons": {
      position: "fixed",
      display: "flex",
      bottom: 24,
      left: 0,
      right: 0,
      padding: "0 16px",
      width: "100%",
    },
  },

  email: {
    fontSize: "24px",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    lineHeight: 1.33,
  },

  sendEmailDone: {
    textAlign: "center",
    marginTop: "50%",
    "& div:nth-child(3n)": {
      fontSize: "24px",
      color: theme.palette.text.primary,
      lineHeight: 1.33,
    },
    "& .button": {
      position: "fixed",
      display: "flex",
      bottom: 24,
      left: 0,
      right: 0,
      padding: "0 16px",
      width: "100%",
    },
  },
}));
