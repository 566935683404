import { useMutation } from "react-query";

import { commonOnSuccess, getAPIData } from "/common/Fn";

/**
 * - 비 암호화 login api
 * - api: /sign/sign-in
 * - path: /hooks/api/useMutation/login/useLogIn.js
 * @param options - mutate options
 * @example
 * const { mutate } = useLogIn()
 * * ...
 *  * mutate({
 *  *    userInfoDTO: {
 *  *        loginId,
 *  *        password
 *  *    }
 *  * })
 *  */

const useLogIn = (options = {}) =>
  useMutation(async (queryData) => await getAPIData("/sign/sign-in", queryData), {
    onSuccess: ({ header }) => commonOnSuccess(header),
    ...options,
  });

export default useLogIn;
